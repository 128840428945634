<template>

     <template v-if="currentSchoolyear && adminSchoolyear">

        <el-card shadow="hover">
            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('yearCutting') }}</h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-button size="default" @click="addItem()">
                            {{ isYearCuttingTerm ? $t('addTerm') : $t('addSemester') }}
                        </el-button>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-table :data="pagedTableData" :empty-text="$t('noData')" style="width: 100%" border stripe v-loading="isLoading">

                <el-table-column type="index" :index="indexMethod"> </el-table-column>
                <el-table-column sortable :label="$t('slice')">
                    <template #default="scope">
                        {{ getYearCuttingLabel(scope.row.code) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('startDate')" prop="start_date" width="125">
                    <template #default="scope">
                        {{ reformatDate(scope.row.start_date) }}
                    </template>
                </el-table-column>
                <el-table-column sortable :label="$t('endDate')" prop="end_date" width="125">
                    <template #default="scope">
                        {{ reformatDate(scope.row.end_date) }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('actions')" align="right" width="325">

                    <template #default="scope">
                        <el-button size="small" type="primary" @click="scheduleEvaluations(scope.$index, scope.row)">
                            {{ $t('scheduleEvaluations') }}
                        </el-button>
                        <el-button size="small" @click="editItem(scope.$index, scope.row)" icon="edit"> </el-button>
                        <el-button size="small" type="danger" @click="deleteItem(scope.$index, scope.row)" icon="delete"> </el-button>
                    </template>

                </el-table-column>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>

            </div>
        </el-card>

        <el-dialog :title="isYearCuttingTerm ? $t('addTerm') : $t('addSemester')" v-model="addDialog" width="60%">

            <el-form :inline="true" :model="addYearCutting" :rules="rules" :ref="addYearCuttingForm">

                <el-form-item prop="code">
                    <el-select
                        filterable
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        v-model="addYearCutting.code"
                        style="width: 250px">
                        <el-option
                            v-for="item in (isYearCuttingTerm ? terms : semesters)"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="range">
                    <el-config-provider :locale="locale">
                        <el-date-picker
                            v-model="addYearCutting.range"
                            type="daterange"
                            :unlink-panels="true"
                            :range-separator="$t('to')"
                            :start-placeholder="$t('startDate')"
                            :end-placeholder="$t('endDate')"/>
                    </el-config-provider>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(addYearCuttingForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(addYearCuttingForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog :title="isYearCuttingTerm ? $t('editTerm') : $t('editSemester')" v-model="editDialog" width="60%">

            <el-form :inline="true" :model="editYearCutting" :rules="rules" :ref="editYearCuttingForm">

                <el-form-item prop="code">
                    <el-select
                        filterable
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        v-model="editYearCutting.code"
                        style="width: 250px">
                        <el-option
                            v-for="item in (isYearCuttingTerm ? terms : semesters)"
                            :key="item.id"
                            :value="item.id"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="range">
                    <el-config-provider :locale="locale">
                        <el-date-picker
                            v-model="editYearCutting.range"
                            type="daterange"
                            :unlink-panels="true"
                            :range-separator="$t('to')"
                            :start-placeholder="$t('startDate')"
                            :end-placeholder="$t('endDate')"/>
                    </el-config-provider>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(editYearCuttingForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(editYearCuttingForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog
            fullscreen
            @close="cancelSchedule()"
            v-model="scheduleEvaluationsDialog"
            :title="$t('scheduleEvaluations') + (currentYearCutting ? (' | ' + getYearCuttingLabel(currentYearCutting.code)) : '')">

            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('addEvaluations') }} <span>{{ (rows && rows.length) ?  ('  (' + rows.length + ')') : '' }}</span></h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-button @click="addRow()" type="info">{{ $t('add') }}</el-button>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-row :gutter="20" v-for="(row, index) in rows" :key="'row_' + index" >
                <el-col :span="6">
                    <label class="block-label">{{ $t('date') }}</label>
                    <el-config-provider :locale="locale">
                        <el-date-picker
                            type="daterange"
                            v-model="row.range"
                            :unlink-panels="true"
                            :range-separator="$t('to')"
                            :start-placeholder="$t('startDate')"
                            :end-placeholder="$t('endDate')"
                            style="width: 100%; margin-top: 5px"/>
                    </el-config-provider>
                </el-col>
                <el-col :span="6">
                    <label class="block-label">{{ $t('type') }}</label>
                    <el-select
                        v-model="row.type"
                        :placeholder="$t('select')"
                        :no-data-text="$t('noData')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 100%; margin-top: 5px">
                        <el-option
                            v-for="item in evaluations"
                            :key="item.id"
                            :value="item.id"
                            :label="item.name">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <label class="block-label">{{ $t('classes') }}</label>
                    <el-select
                        multiple
                        filterable
                        collapse-tags
                        v-model="row.classes"
                        :no-data-text="$t('noData')"
                        :placeholder="$t('selectClasses')"
                        :no-match-text="$t('noMatchingData')"
                        style="width: 100%; margin-top: 5px">
                        <el-option
                            v-for="item in classes"
                            :key="item.id"
                            :value="item.id"
                            :label="getClassName(item.id)">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <label class="block-label">{{ $t('displayToTeachers') }}</label>
                    <el-checkbox
                        style="width: 100%; margin-top: 5px"
                        :label="$t('display')"
                        v-model="row.display"
                        border/>
                </el-col>
                <el-col :span="3">
                    <label class="block-label">{{ $t('actions') }}</label>
                    <el-button @click.prevent="removeRow(row)" style="width: 100%; margin-top: 5px">{{ $t('delete') }}</el-button>
                </el-col>
                <el-divider></el-divider>
            </el-row>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelSchedule()">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitSchedule()">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

     </template>

     <el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else/>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import fr from 'element-plus/lib/locale/lang/fr'

import { SchoolData, YearCuttingTypes, YearTerms, YearSemesters } from '@/utils/enums'

import { reformatDate, formatDate } from '@/utils/chronos'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'YearCutting',
    mixins: [accessManager],
    data() {
      return {
        rows: [],
        terms: [],
        classes: [],
        semesters: [],
        evaluations: [],
        establishments: [],
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        editYearCutting: {
            code: '',
            range: ''
        },
        addYearCutting: {
            code: '',
            range: ''
        },
        rules: {
            code: [
                {
                    required: false
                }
            ],
            range: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ]
        },
        editYearCuttingForm: 'editYearCuttingForm',
        addYearCuttingForm: 'addYearCuttingForm',
        scheduleEvaluationsDialog: false,
        currentYearCutting: null,
        currentEditItem: null,
        editDialog: false,
        isLoading: false,
        addDialog: false,
        tableData: [],
        pageSize: 6,
        locale: fr,
        search: '',
        total: 0,
        page: 1
      }
    },
    created() {

      this.fetchYearCuttings()

      var self = this

      this.$_.forIn(YearSemesters, function(value, key) {

          self.semesters.push(value)

      })

      this.$_.forIn(YearTerms, function(value, key) {

          self.terms.push(value)

      })

    },
    computed:{
      ...mapState({
        userData: state => state.session.userData
      }),
      ...mapGetters({
        getSettingValue: 'settings/getSettingValue',
        getSchoolData: 'schooldata/getSchoolDataValue'
      }),
      pagedTableData() {

        this.total = this.searching.length

        return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

      },
      searching() {

        if (!this.search) {
            this.total = this.tableData.length
            return this.tableData
        }

        this.page = 1

        return this.tableData.filter(data => (
            this.getYearCuttingLabel(data.code).toLowerCase().includes(this.search.toLowerCase()) ||
            data.start_date.toLowerCase().includes(this.search.toLowerCase()) ||
            data.end_date.toLowerCase().includes(this.search.toLowerCase())
        ))

      },
      yearCuttingType() {

        return this.getSchoolData(SchoolData.YEAR_CUTTING_TYPE.NAME)

      },
      isYearCuttingTerm() {

        return this.yearCuttingType == YearCuttingTypes.TERM.id

      }
    },
    methods: {
      reformatDate,
      ...mapActions({
         fetchClassesDB: 'classes/fetchClassesDB',
         fetchEvaluationsDB: 'evaluations/fetchEvaluationsDB',
         fetchYearCuttingsDB: 'yearcuttings/fetchYearCuttingsDB',
         getUserByCredentials: 'authentication/getUserByCredentials',
         fetchEstablishmentsDB: 'establishments/fetchEstablishmentsDB',
         fetchEvaluationTypesDB: 'evaluationtypes/fetchEvaluationTypesDB',
      }),
      setPage (val) {

         this.page = val

      },
      indexMethod(index) {

         return index + ((this.page - 1) * this.pageSize) + 1

      },
      getYearCuttingLabel(id){

          let yearcutting = (this.isYearCuttingTerm ? this.terms : this.semesters).find(e => e.id == id)

          return yearcutting ? yearcutting.label : ''

      },
      getClassName(id){

          let c = this.classes.find(c => c.id == id)

          return c ? (c.name + ' ' + this.getEstablishmentLocation(c.establishment)) : ''

      },
      getEstablishmentLocation(id){

          let establishment = this.establishments.find(e => e.id == id)

          return establishment ? establishment.location : ''

      },
      async fetchYearCuttings(){

        if (!this.adminSchoolyear) return

        this.isLoading = true

        let resp1 = await this.fetchEvaluationTypesDB({
            action: 'getEvaluationTypes'
        })

        if (resp1.success && resp1.data) this.evaluations = resp1.data

        let resp2 = await this.fetchClassesDB({
            action: 'getClasses',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp2.success && resp2.data) this.classes = resp2.data

        let resp3 = await this.fetchEstablishmentsDB({
            action: 'getEstablishments',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp3.success && resp3.data) this.establishments = resp3.data

        let resp = await this.fetchYearCuttingsDB({
            action: 'getYearCuttings',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp.success){

            if (resp.data) this.tableData = resp.data

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

        this.isLoading = false

      },
      addItem(){

           if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
           }

           this.addDialog = true

      },
      async editItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.currentEditItem = item

            this.editYearCutting.code = parseInt(item.code)

            let start = item.start_date.split('-')
            let end = item.end_date.split('-')

            if (start.length === 3 && end.length === 3){

                this.editYearCutting.range = [
                    new Date(start[0], parseInt(start[1]) - 1, start[2]),
                    new Date(end[0], parseInt(end[1]) - 1, end[2])
                ]

            }

            this.editDialog = true

      },
      deleteItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.$prompt(this.isYearCuttingTerm ? this.$t('deleteTermQuestion') : this.$t('deleteSemesterQuestion'),
                         this.isYearCuttingTerm ? this.$t('deleteTerm') : this.$t('deleteSemester'), {
                inputPlaceholder: this.$t('confirmWithPassword'),
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel'),
                inputType: 'password'
            })
            .then(async ({ value }) => {

                if (value){

                    let resp = await this.getUserByCredentials({
                        username: this.userData.uid,
                        password: value
                    })

                    if (resp.success){

                        if (resp.data.username && resp.data.password && resp.data.user){

                            let task = await this.fetchYearCuttingsDB({
                                action: 'deleteYearCutting',
                                params: {
                                    id: item.id
                                }
                            })

                            if (task.success){

                                this.$message.success(this.$t('success'))
                                this.fetchYearCuttings()

                            }else{

                                this.$message.error(this.$t('anErrorOccured'))

                            }

                        } else {

                            this.$message.error(this.$t('wrongPassword'))

                        }

                    }else{

                        this.$message.error(this.$t('anErrorOccured'))

                    }

                }

            })
            .catch(() => {

            })

      },
      submitForm(ref){

            this.$refs[ref].validate(async (valid) => {
                if (valid) {

                    if (ref === this.addYearCuttingForm){

                        let task = await this.fetchYearCuttingsDB({
                            action: 'addYearCutting',
                            params: {
                                code: this.addYearCutting.code,
                                start_date: formatDate(this.addYearCutting.range[0], {outPattern: 'YYYY-MM-DD'}),
                                end_date: formatDate(this.addYearCutting.range[1], {outPattern: 'YYYY-MM-DD'}),
                                type: this.yearCuttingType,
                                schoolyear: this.adminSchoolyear
                            }
                        })

                        if (task.success) this.$message.success(this.$t('success'))
                        else this.$message.error(this.$t('anErrorOccured'))

                    }else{

                        let newData = {}

                        let startDate = formatDate(this.editYearCutting.range[0], {outPattern: 'YYYY-MM-DD'}),
                            endDate = formatDate(this.editYearCutting.range[1], {outPattern: 'YYYY-MM-DD'})

                        if (this.currentEditItem.code !== this.editYearCutting.code) {
                            newData['code'] = this.editYearCutting.code
                        }

                        if (this.currentEditItem.start_date !== startDate) {
                            newData['start_date'] = startDate
                        }

                        if (this.currentEditItem.end_date !== endDate) {
                            newData['end_date'] = endDate
                        }

                        if (!this.$_.isEmpty(newData)){
                            let task = await this.fetchYearCuttingsDB({
                                action: 'editYearCutting',
                                params: {
                                    id: this.currentEditItem.id,
                                    data: newData
                                }
                            })

                            if (task.success) this.$message.success(this.$t('success'))
                            else this.$message.error(this.$t('anErrorOccured'))
                        }

                    }

                    this.cancelForm(ref)
                    this.fetchYearCuttings()

                } else {
                    return false
                }
            })

      },
      cancelForm(ref){

            this.$refs[ref].resetFields()

            if (ref === this.addYearCuttingForm) this.addDialog = false
            else this.editDialog = false

      },
      removeRow(row){

        var index = this.rows.indexOf(row)

        if (index !== -1) this.rows.splice(index, 1)

      },
      addRow(){

        this.rows.push({
            display: false,
            classes: [],
            range: '',
            type: ''
        })

      },
      async scheduleEvaluations(index, item){

        if (this.lockedData){
            this.$message.warning(this.$t('lockedData'))

            return
        }

        this.currentYearCutting = item

        let resp = await this.fetchEvaluationsDB({
            action: 'getYearCuttingEvaluations',
            params: {
                yearcutting: item.id,
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp.success){

            if (resp.data) {

                let data = []

                resp.data.forEach(v => {

                    let id = data.findIndex(d => (d.start_date == v.start_date && d.end_date == v.end_date && d.type == v.type))
                    if (id > -1){

                        data[id].classes.push(v.class)

                    }else{

                        let start = v.start_date.split('-')
                        let end = v.end_date.split('-')
                        let range = null

                        if (start.length === 3 && end.length === 3){

                            range = [
                                new Date(start[0], parseInt(start[1]) - 1, start[2]),
                                new Date(end[0], parseInt(end[1]) - 1, end[2])
                            ]

                        }

                        data.push({
                            type: v.type,
                            range: range,
                            classes: [v.class],
                            display: parseInt(v.display) != 0,
                            start_date: v.start_date,
                            end_date: v.end_date
                        })

                    }

                })

                data.forEach(v => {

                    this.rows.push({
                        display: v.display,
                        classes: v.classes,
                        range: v.range,
                        type: v.type
                    })

                })

            }

            this.scheduleEvaluationsDialog = true

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

      },
      async submitSchedule(){

        if (this.rows && this.currentYearCutting){

            let filteredRows = this.rows.filter(r => (r.classes && r.range && r.type))

            if (filteredRows && filteredRows.length){

                let finalRows = []

                filteredRows.forEach(row => {

                    let newData = {}

                    let startDate = formatDate(row.range[0], {outPattern: 'YYYY-MM-DD'}),
                        endDate = formatDate(row.range[1], {outPattern: 'YYYY-MM-DD'})

                    newData['type'] = row.type
                    newData['display'] = row.display ? 1 : 0
                    newData['classes'] = row.classes
                    newData['start_date'] = startDate
                    newData['end_date'] = endDate

                    finalRows.push(newData)

                })

                let task = await this.fetchEvaluationsDB({
                    action: 'updateEvaluation',
                    params: {
                        schoolyear: this.adminSchoolyear,
                        yearcutting: this.currentYearCutting.id,
                        data: finalRows
                    }
                })

                if (task.success) this.$message.success(this.$t('success'))
                else this.$message.error(this.$t('anErrorOccured'))

            } else {

                await this.fetchEvaluationsDB({
                    action: 'updateEvaluation',
                    params: {
                        schoolyear: this.adminSchoolyear,
                        yearcutting: this.currentYearCutting.id,
                        data: 'clear'
                    }
                })

            }

        }

        this.cancelSchedule()

      },
      cancelSchedule(){

        this.scheduleEvaluationsDialog = false
        this.currentYearCutting = null
        this.rows = []

      }
    },
}
</script>

<style scoped>
    .inline-label{
        width: 20%;
        font-weight: bold;
        display: inline-block;
    }

    .block-label{
        font-weight: normal;
        font-size: .9em;
    }
</style>